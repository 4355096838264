export const CLICK_FUNNEL_BASE_URL = (window.FvM && window.FvM.links && window.FvM.links.CLICK_FUNNEL_BASE_URL) ? window.FvM.links.CLICK_FUNNEL_BASE_URL : 'https://www.immosmart24.com/baufinanzierungsrechner-anfrage';
export const CLICK_FUNNEL_WINDOW_TARGET = (window.FvM && window.FvM.links && window.FvM.links.CLICK_FUNNEL_WINDOW_TARGET) ? window.FvM.links.CLICK_FUNNEL_WINDOW_TARGET : '_blank';
export const CALCULATOR_BASE_URL = (window.FvM && window.FvM.links && window.FvM.links.CALCULATOR_BASE_URL) ? window.FvM.links.CALCULATOR_BASE_URL : '/';


/*** copy to click Funnel to load from another domain. Adjust URLs as required ***/
//<script>
// window.FvM = {
//   CALCULATOR_BASE_URL: 'https://www.bieber-vermoegensberatung.de/hubfs/baufinanzierungsrechner/',
//   CLICK_FUNNEL_BASE_URL: 'https://www.immosmart24.com/baufinanzierungsrechner-anfrage'
// }
//</script>
import React from 'react';
import { BeatLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';


const useSelectStyles = makeStyles((theme) => ({
  fvmLoadingSpinner: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    width: ' 100%',
    height: '100%',
    top: 0,
    zIndex: 200,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  spinnerItem: {
    color: `${theme.palette.primary.main}`,
  },
}));

const{fvmPrimaryColor} = window.FvM ? window.FvM.theme.color : {};
const Index = (props) => {
  const classes = useSelectStyles();

  return (
    <div className={`${classes.fvmLoadingSpinner}`}>
      <BeatLoader
        color={fvmPrimaryColor}
        loading
      />
    </div>
  );
};

export default Index;

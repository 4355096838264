import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { isMobile } from 'react-device-detect';
import { HelpOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  helpIcon: {
    margin: 'auto',
    paddingTop: 8,
  },
}));

const toolTipTextDefault = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat';


export const FvmHelpIcon = ({ toolTipText = toolTipTextDefault }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const props = {
    onClick: handleTooltipOpen,
    disableFocusListener: true,
    disableHoverListener: true,
    disableTouchListener: true,
    onClose: handleTooltipClose,
    open,
  };
  return (
    <span className={classes.helpIcon}>
     <ClickAwayListener onClickAway={handleTooltipClose}>
    <Tooltip
      title={toolTipText}
      placement="top"
      arrow
      {...(isMobile ? props : {})}
    >
      <HelpOutline/>
    </Tooltip>
     </ClickAwayListener>
  </span>);

};
import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    whiteSpace: 'nowrap',
  },
  selectedYearCol: {
    color: `${theme.palette.primary.main}`,
  },
  slider: {
    boxShadow: 'none',
    overflow: 'hidden',
  },
}));


export const FvmTableSwiper = ({ rows, columns, slidesPerView, yearsValue, tableHeader, textResource= {} }) => {
  const classes = useStyles();
  const [localSlidesPerView, setLocalSlidesPerView] = React.useState(false);
  const swiper = useRef(null);
  useEffect(() => {
    swiper.current = new Swiper('.swiper-container', { slidesPerView, updateOnWindowResize: true, initialSlide: columns.indexOf(`${yearsValue}`) });
  }, []);

  if (swiper.current) {
    swiper.current && swiper.current.slideTo(columns.indexOf(`${yearsValue}`));

    if (swiper.current && localSlidesPerView !== slidesPerView) {
      setLocalSlidesPerView(slidesPerView);
      swiper.current.destroy();
      swiper.current = new Swiper('.swiper-container', { slidesPerView, updateOnWindowResize: true, initialSlide: columns.indexOf(`${yearsValue}`) });
    }
  }

  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        {columns.map(tHeadField => (
          <div className="swiper-slide" >
            <TableContainer className={classes.slider} component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow key={`able-row-${tHeadField}`}>
                    <TableCell
                      className={yearsValue === Number(tHeadField) ? `${classes.selectedYearCol} ${classes.tableHeadCell}` : classes.tableHeadCell}
                      align={slidesPerView === 1 ? 'center' : 'left'}>{`${tHeadField} ${tableHeader.years}`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={`table-row-${index}-${tHeadField}`}>
                      <TableCell className={yearsValue === Number(tHeadField) ? classes.selectedYearCol : ''}
                                 align={slidesPerView === 1 ? 'center' : 'left'}>{!rows[0][tHeadField] ? textResource.notValidTxt  : row.formatter(row[tHeadField])} {rows[0][tHeadField] ? row.postFix: ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </div>
    </div>);
};
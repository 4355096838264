import * as Papa from 'papaparse';
import { deStrToNum } from './use-window-dimensions';
import {  CALCULATOR_BASE_URL } from '../ApplicationConstans';

let bundeslandObj = {};
let plzBundesland = {};
let ortBundesland = {};
let Finanzierungsvorhaben = [];
let BerufDesHauptverdieners = [];
let Immobilientyp = [];
let NutzungDerImmobilie = [];


const getSitesConstant = async (callBk = () => { }) => await fetch(`${CALCULATOR_BASE_URL}assets/input-consts/ApplicationConst.json`)
  .then(response => response.json())
  .then(data => {
    callBk(data);
    Finanzierungsvorhaben = data.Finanzierungsvorhaben;
    BerufDesHauptverdieners = data.BerufDesHauptverdieners;
    Immobilientyp = data.Immobilientyp;
    NutzungDerImmobilie = data.NutzungDerImmobilie;
    return data;
  })
  .then(data => console.log(data));

getSitesConstant();

const fetchText = async (callBk) => await fetch(`${CALCULATOR_BASE_URL}assets/locale/de.json`)
  .then(response => response.json())
  .then(data => {callBk(data)});

const fetchMaklerCsv = (callBk = () => { }) => {
  Papa.parse(`${CALCULATOR_BASE_URL}assets/input-consts/maklerprovision.csv`, {
    download: true,
    header: true,
    complete: ({ data }) => {
      data.forEach(({ bundesland, ...rest }) => {
        const adjustedBundesland = bundesland.replace('ü', 'ue');
        bundeslandObj[adjustedBundesland] = { ...(bundeslandObj[adjustedBundesland] ? bundeslandObj[adjustedBundesland] : {}), ...rest };
        if(bundeslandObj[adjustedBundesland]['maklerprovision gesamt']){
          bundeslandObj[adjustedBundesland].maklerprovisionGesamt =  Math.round(deStrToNum(bundeslandObj[adjustedBundesland]['maklerprovision gesamt'] + '') * 1000) / 1000;
        }
      });
      callBk(bundeslandObj);
    },
  });
};

const fetchGrunderwerbsteuer = ( callBk) => {
  bundeslandObj = {};
  Papa.parse(`${CALCULATOR_BASE_URL}assets/input-consts/grunderwerbsteuer.csv`, {
    download: true,
    header: true,
    complete: ({ data }) => {
      data.forEach(({ bundesland, ...rest }) => {
        const adjustedBundesland = bundesland.replace('ü', 'ue');
        bundeslandObj[adjustedBundesland] = { ...(bundeslandObj[adjustedBundesland] ? bundeslandObj[adjustedBundesland] : {}), ...rest };
        if(bundeslandObj[adjustedBundesland].prozentsatz){
          bundeslandObj[adjustedBundesland].prozentsatz = Math.round(deStrToNum(bundeslandObj[adjustedBundesland].prozentsatz ) * 1000) / 10;
        }
      });
      fetchMaklerCsv(callBk);
    },
  });
};

const fetchZinssatz = (callBk = () => {}) => {
  const zinssatz = {};
  Papa.parse(`${CALCULATOR_BASE_URL}assets/input-consts/zinssatz.csv`, {
    download: true,
    header: true,
    complete: ({ data }) => {
      data.forEach(({ beleihungswert, zinsbindung, ...rest }) => {
        zinssatz[`${zinsbindung}-${beleihungswert}`] = {
          effektiverJahreszins: Math.round(deStrToNum(rest['effektiver jahreszins'] + '') * 1000) /1000,
          gebundenerSollzins: Math.round(deStrToNum(rest['gebundener sollzins'] + '') * 1000) /1000,
          ...rest
        };
      });
      callBk(zinssatz);
    },
  });
};

const fetchBundeslandPlzUndOrt = (callBk) => {
  Papa.parse(`${CALCULATOR_BASE_URL}assets/input-consts/zuordnungplzortbundesland.csv`, {
    download: true,
    header: true,
    complete: function ({ data }) {
      data.filter(({plz}) => plz).forEach(({ plz, ort, ...rest }) => {
        plzBundesland[plz] = { ort, plz, ...rest };
        ortBundesland[ort] = { plz, ort, ...rest };
      });
      callBk({ plzBundesland, ortBundesland });
    },
  });
};

export const getZinssatz = (callBk) => fetchZinssatz(callBk);
export const getBundesLandObj = (callBk) => fetchGrunderwerbsteuer(callBk);
export const getPlzUnOrtBundesland = (callBk) => fetchBundeslandPlzUndOrt(callBk);
export const getTextResource = (callBk) => fetchText(callBk);
export const getSelectOptions = (callBk) => getSitesConstant(callBk);

export {
  Finanzierungsvorhaben,
  BerufDesHauptverdieners,
  Immobilientyp,
  NutzungDerImmobilie
};
import { ThemeProvider, createMuiTheme, responsiveFontSizes, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import './App.css';
import { Calculator } from './views/Calculator';
import React, { useEffect } from 'react';
import { getBundesLandObj, getZinssatz, getTextResource, getPlzUnOrtBundesland } from './utilities/resources-loader';

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: 50,
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    minHeight: '100%',
  },

}));

function App() {
  const classes = useStyles();
  const [textResource, setTextResource] = React.useState(null);
  const [bundeslandObj, setBundesLandObj] = React.useState({});
  const [zinssatz, setZinssatz] = React.useState({});
  const [plzBundesland, setPlzBundesland] = React.useState({});
  const [ortBundesland, setOrtBundesland] = React.useState({});

  useEffect(() => {
    getTextResource(setTextResource);
    getBundesLandObj(setBundesLandObj);
    getZinssatz(setZinssatz);
    getPlzUnOrtBundesland(({ plzBundesland, ortBundesland }) => {
      setPlzBundesland(plzBundesland);
      setOrtBundesland(ortBundesland);
    });

  }, []);

  const muiTheme = useTheme();
  const theme = responsiveFontSizes(createMuiTheme({
    ...muiTheme,
    overrides: {
      MuiTooltip: {
        tooltip:{
          fontSize: '0.875rem',
          fontWeight: '400'
        }
      },
    },
  }));

  const{fvmPrimaryColor, fvmPrimaryColorHover} = window.FvM ? window.FvM.theme.color : {};
  theme.palette.primary = {
    ...theme.palette.primary,
    contrastText: '#fff',
    main: fvmPrimaryColor || '#A7893D',
    dark: fvmPrimaryColorHover || '#A7893D',
  };

  theme.palette.secondary = {
    ...theme.palette.secondary,
    // contrastText: '#F2E7FE',
    main: '#F2E7FE',
    dark: '#F2E7FE',
  };

  theme.breakpoints.values = {
    ...theme.breakpoints.values,
    calculator: 375,
    tablet: 640,
    laptop: 1024,
    desktop: 1280,
  };
  theme.breakpoints.keys = [...theme.breakpoints.keys, 'laptop'];
  // textResource && bundeslandObj && zinssatz && plzBundesland && ortBundesland && console.log({ bundeslandObj, zinssatz, plzBundesland, ortBundesland });

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        {
          textResource && bundeslandObj && zinssatz && plzBundesland && ortBundesland
            ? <Calculator textResource={textResource} resources={{ bundeslandObj, zinssatz, plzBundesland, ortBundesland }}/>
            : null
        }
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
// import * as textResource from '../../assets/locale/de.json';
import Container from '@material-ui/core/Container';
// import ArrowBack from '@material-ui/icons/ArrowDropDownCircle'
// import ArrowBack from '@material-ui/icons/ArrowBack';
import { ArrowForward, ArrowBack, Remove, ArrowForwardIos, Add, DragHandle, Help, HelpOutline } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Accordion, AccordionDetails, AccordionSummary, Box,
  Button,
  Divider,
  FormControl, FormControlLabel,
  Grid, IconButton,
  InputLabel, InputAdornment,
  MenuItem, Paper,
  Select, Switch, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField,
  Typography, ClickAwayListener, Input,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y } from 'swiper';

import Swiper from 'swiper';

import LoadingSpinner from '../../components/LoadingSpinner';
// install Swiper components
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import useWindowDimensions from '../../utilities/use-window-dimensions';
import { FvmHelpIcon } from '../../components/FvmHelpIcon';
import { FvmTableSwiper } from '../../components/FvmTableSwiper';

import {  CLICK_FUNNEL_BASE_URL } from '../../ApplicationConstans';
import {  CLICK_FUNNEL_WINDOW_TARGET } from '../../ApplicationConstans';
import {  Finanzierungsvorhaben, BerufDesHauptverdieners, Immobilientyp, NutzungDerImmobilie } from '../../utilities/resources-loader';

SwiperCore.use([Navigation, A11y]);

Navigation.params.navigation.nextEl = '.fvm-nextElm-button';
Navigation.params.navigation.prevEl = '.fvm-prevElm-button';

const width = '100%';
const height = 48;

// let { Finanzierungsvorhaben, BerufDesHauptverdieners, Immobilientyp, NutzungDerImmobilie } = [];

// console.log(Finanzierungsvorhaben)
// const setSelectConst = (values = {}) => {
//   Finanzierungsvorhaben = values.Finanzierungsvorhaben;
//   BerufDesHauptverdieners = values.BerufDesHauptverdieners;
//   Immobilientyp = values.Immobilientyp;
//   NutzungDerImmobilie = values.NutzungDerImmobilie;
// };

// getSelectOptions(setSelectConst);


const useButtonGroupStyles = makeStyles((theme) => ({
  root: {
    '&$selected': {
      color: '#FFFFFF',
      backgroundColor: `${theme.palette.primary.main}`,
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: `${theme.palette.primary.main}`,
      },
    },
  },
  'selected': {
    color: '#FFFFFF',
    backgroundColor: `${theme.palette.primary.main}`,
  },
}), { name: 'MuiToggleButton' });

const useSelectStyles = makeStyles((theme) => ({
  root: {
    '&$selected': {
      color: '#000000',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        color: '#000000',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  },
  'selected': {
    color: '#000000',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
}), { name: 'MuiListItem' });

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'center',
    maxWidth: 1024,
    // backgroundColor: '#F2E7FE',
    padding: 16,
  },
  calculatorContainerInnerGrid: {
    width,
    '& >div': {
      display: 'inline-flex',
      padding: '0 8px 8px 8px',
    },
    '& >div:first-child': {
      paddingTop: 8,
    },
    '& >div:nth-last-child(2)': {
      padding: '0 0 16px 0',
    },
  },
  accordionInnerGrid: {
    width,
    '& >div': {
      display: 'inline-flex',
      padding: '0 8px 8px 8px',
    },
  },
  calculatorBoxContainer: {
    maxWidth: '100%',
  },
  calculatorContainer: {
    paddingBottom: 15,
  },
  calculationResultPaperContainer: {
    // padding: '16px 16px 0 16px',
    padding: 16,
  },
  calculationHeaderPaperGrid: {
    backgroundColor: theme.palette.primary.main,
    height: 112,
    padding: 0,
    '&>div:not(:last-child)': {
      borderRight: '1px solid #FFFFFF',
    },
    '&>div': {
      padding: '0 16px',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      color: '#FFFFFF',
    },
  },
  calculationResultsContainer: {
    paddingBottom: 15,
    width,
  },
  errorMsg: {
    color: 'red',
  },
  detailedFeesOutlineContainer: {
    padding: 16,
  },
  'MuiToggleButton-root Mui-selected': {
    backgroundColor: theme.palette.primary.main,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  formControlTextField: {
    margin: theme.spacing(1),
    width: 'calc(100% - 40px)',
    height,
  },
  formControlSelect: {
    margin: theme.spacing(1),
    width: 'calc(100% - 40px)',
    height,
  },
  formControlAccordionSelect: {
    maxWidth: 'calc(100% - 40px)',
  },
  formButtonGroupLabel: {
    margin: '4px 8px -8px 20px',
    fontSize: '0.75em',
  },
  formControlButtonGroup: {
    margin: theme.spacing(1),
    width: 'calc(100% - 40px)',
    height,
    '& button': {
      width: `${100 / 6}%`,
    },
  },
  accordion: {
    width: 'calc(100%)',
  },
  durationAccordion: {
    width: 'calc(100%)',
  },
  durationAccordionGridContainer: {
    maxWidth: 'calc(100%)',
  },
  accordionDetails: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '& >div': {
      paddingBottom: 16,
    },
  },
  fixedColumn: {
    minWidth: 190,
    width: 190,
    zIndex: 7,
  },
  tableFootnote: {
    marginLeft: 16,
    marginBottom: 8
  },
  swiperControls: {
    marginRight: 'auto',
    marginLeft: 190,
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      display: 'inline-flex',
      verticalAlign: 'middle',
      alignItems: 'center',
      cursor: 'pointer',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  slider: {
    boxShadow: 'none',
    overflow: 'hidden',
  },
  hideText: {
    color: 'transparent',
  },
  tableBox: {
    display: 'flex',
  },
  resultsListSubEntry: {
    marginLeft: 15,
    display: 'inline-flex',
  },
  subLstItemIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& svg': {
      fontSize: '0.75rem',
    },
    marginLeft: 24,
  },
  mainTitleIcon: {
    marginRight: 10,
  },
  textInline: {
    display: 'inline-flex',
  },
  resultsBoxContainer: {
    maxWidth: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  [theme.breakpoints.up(768)]: {
    resultsBoxContainer: {
      maxWidth: `calc(100% - ${320 + 16}px)`,
    },
    calculatorBoxContainer: {
      maxWidth: 320,
    },
  },
  [theme.breakpoints.down('sm')]: {
    swiperControls: {
      '& .fvm-nextElm-button>span': {
        display: 'none',
      },
      '& .fvm-prevElm-button>span': {
        display: 'none',
      },

    },
  },
  [theme.breakpoints.down(768)]: {
    container: {
      flexDirection: 'column',
      padding: 0,
      '& >:not(:last-child)': {
        marginBottom: 20,
      },
    },
  },
  [theme.breakpoints.down(992)]: {
    calculationHeaderPaperGrid: {
      '&>div h4': {
        fontSize: '1.2rem',
      },
    },
  },
}));

const [xs] = [12, 4];
const textFieldVariant = 'outlined';

const localeMoneyFormatter = (num, locale = 'de-DE', currency = 'EUR') => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: 'EUR',
}).format(num || 0);

const localeNumberFormatter = (num, locale = 'de-DE') => new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num || 0);

function NumberFormatInput({ inputRef, onChange, ...props }) {
  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}


function NumberFormatMoneyDE(props) {
  return NumberFormatInput({ ...props, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, allowNegative: false  });
}

function NumberFormatPercentageInputDE(props) {
  return NumberFormatMoneyDE({ ...props, fixedDecimalScale: true });
}


function NumberFormatInputPostCode(props) {
  return NumberFormatInput({...props, allowNegative: false});
}


const filterOptions = createFilterOptions({
  matchFrom: 'start',
  trim: true,
});


export const Calculator = ({ textResource, resources }) => {
  const classes = useStyles();
  const [calculatorParams, setCalculatorParams] = React.useState({});
  const [purchasePrice, setPurchasePrice] = React.useState(null);
  const [equityCapital, setEquityCapital] = React.useState(null);
  const [yearsValue, setYearsValue] = React.useState(10);
  const { buttons, header, sections, tableHeader, tableText, tableRows, errorLables,footnotesTxt } = textResource.labels;
  const [repaymentRatePercent, setRepaymentRatePercent] = React.useState(2);
  const [monthlyRateFixed, setMonthlyRateFixed] = React.useState(null);
  const [currentResidencePostcode, setCurrentResidencePostcode] = React.useState();
  const [currentLocation, setCurrentLocation] = React.useState();
  const [interestType, setInterestType] = React.useState('percentage');
  const [financingProject, setFinancingProject] = React.useState('');
  const [propertyType, setPropertyType] = React.useState('');
  const [occupationOfMainEarner, setOccupationOfMainEarner] = React.useState('');
  const [propertyUse, setPropertyUse] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeBundesland, setActiveBundesland] = React.useState({});
  const [isFormHasErrors, setIsFormHasErrors] = React.useState(true);
  const { zinssatz, bundeslandObj, plzBundesland } = resources;

  const handleCalculationParamsChange = (calculatorParams) => {
    const {
      purchasePrice,
      equityCapital,
      yearsValue,
      interestType,
      repaymentRatePercent,
      monthlyRateFixed,
      currentResidencePostcode,
      currentLocation,
      financingProject,
      propertyType,
      occupationOfMainEarner,
      propertyUse,
      isFormHasErrors,
    } = calculatorParams;

    let inputsChanged = false;

    if (purchasePrice !== undefined) {
      setPurchasePrice(purchasePrice);
    }
    if (equityCapital !== undefined) {
      setEquityCapital(equityCapital);
    }
    if (yearsValue) {
      setYearsValue(yearsValue);
    }
    if (interestType !== undefined) {
      setInterestType(interestType);

    }
    if (repaymentRatePercent !== undefined) {
      setRepaymentRatePercent(repaymentRatePercent);
    }
    if (monthlyRateFixed !== undefined) {
      setMonthlyRateFixed(monthlyRateFixed);
    }
    if (currentResidencePostcode) {
      setCurrentResidencePostcode(currentResidencePostcode);
      let activeBundesland = currentResidencePostcode.trim().length === 5 && plzBundesland[currentResidencePostcode];
      if (activeBundesland) {
        const { bundesland, ort } = activeBundesland;
        activeBundesland = {
          ...activeBundesland,
          ...bundeslandObj[bundesland.replace('ü', 'ue')],
        };
        setActiveBundesland(activeBundesland);
        setCurrentLocation(`${ort}, ${bundesland}`);
      }
    }
    if (currentLocation !== undefined) {
      setCurrentLocation(currentLocation);
    }
    if (financingProject) {
      setFinancingProject(financingProject);
    }
    if (propertyType) {
      setPropertyType(propertyType);
    }
    if (occupationOfMainEarner) {
      setOccupationOfMainEarner(occupationOfMainEarner);
    }
    if (propertyUse) {
      setPropertyUse(propertyUse);
    }

    if (isFormHasErrors !== undefined) {
      setIsFormHasErrors(isFormHasErrors);
      if (!isFormHasErrors) {
        inputsChanged = true;
      }
      hideLoaderAfterTime();
    }

    if (inputsChanged) {
      setIsLoading(true);
      hideLoaderAfterTime();
    }


    setCalculatorParams({
      purchasePrice,
      equityCapital,
      yearsValue,
      interestType,
      repaymentRatePercent,
      monthlyRateFixed,
      currentResidencePostcode,
      currentLocation,
      financingProject,
      propertyType,
      occupationOfMainEarner,
      propertyUse,
    });
  };

  const hideLoaderAfterTime = (time = 2000) => {
    setTimeout(() => {
      setIsLoading(false);
    }, time);
  };

  const printParams = () => console.log(calculatorParams);

  const swiperColumnMap = [
    { maxWidth: 475, slidesPerView: 1 },
    { maxWidth: 576, slidesPerView: 2 },
    { maxWidth: 768, slidesPerView: 3 },
    { maxWidth: 992, slidesPerView: 1 },
    { maxWidth: 100000, slidesPerView: 4 },
  ];

  const handleSlidesPerViewChange = ({ width }) => {
    const { slidesPerView } = swiperColumnMap.find(({ maxWidth }) => width < maxWidth);
    setSlidesPerView(slidesPerView);
  };

  const handleOnSubmitClicked = () => {
    const { select } = textResource.labels;
    const isPercentageInterest = interestType === 'percentage';
    const urlParams = [
      `plz=${currentResidencePostcode}`,
      `ort=${currentLocation}`,
      `kaufpreis=${localeNumberFormatter(purchasePriceValue)}`,
      `eigenkapital=${localeNumberFormatter(equityCapitalValue)}`,
      `darlehensbetrag=${localeNumberFormatter(loanTotal)}`,
      `sollzinsbindung=${yearsValue}`,
      `tilgungsvariante=${isPercentageInterest ? select.repaymentRateInPercent : select.monthlyRateAsFixed}`,
      `tilgungssatz=${isPercentageInterest ? localeNumberFormatter(repaymentRatePercentValue) : ''}`,
      `monatsrate=${isPercentageInterest ? '' : localeNumberFormatter(monthlyRateFixedValue)}`,
      `finanzierungsvorhaben=${financingProject === 'Bitte auswählen' ? '' : financingProject}`,
      `immobilientyp=${propertyType === 'Bitte auswählen' ? '' : propertyType}`,
      `beruf=${occupationOfMainEarner === 'Bitte auswählen' ? '' : occupationOfMainEarner}`,
      `nutzung=${propertyUse === 'Bitte auswählen' ? '' : propertyUse}`,
    ];
    const finalUrl = `${CLICK_FUNNEL_BASE_URL}?${urlParams.join('&')}`;
    // console.log(urlParams);
    // console.log(encodeURI(finalUrl));
    // console.log(`${CLICK_FUNNEL_WINDOW_TARGET}`);
    window.open(encodeURI(finalUrl), `${CLICK_FUNNEL_WINDOW_TARGET}`);
  };

  const { width } = useWindowDimensions(handleSlidesPerViewChange);
  const [slidesPerView, setSlidesPerView] = useState(swiperColumnMap.find(({ maxWidth }) => width < maxWidth).slidesPerView);
  const newCalcParams = {
    purchasePrice,
    equityCapital,
    yearsValue,
    interestType,
    repaymentRatePercent,
    monthlyRateFixed,
    currentResidencePostcode,
    currentLocation,
    financingProject,
    propertyType,
    occupationOfMainEarner,
    propertyUse,
  };

  const purchasePriceValue = Number(purchasePrice);
  const equityCapitalValue = Number(equityCapital);
  const monthlyRateFixedValue = Number(monthlyRateFixed);
  const repaymentRatePercentValue = Number(repaymentRatePercent);

  const agentFee = purchasePriceValue * activeBundesland['prozentsatz'] / 100;
  const landTaxes = purchasePriceValue * activeBundesland['maklerprovisionGesamt'] / 100;
  const notaryFee = purchasePriceValue * 2 / 100;
  const totalAdditionalCost = agentFee + notaryFee + landTaxes;
  const loanTotal = purchasePriceValue + totalAdditionalCost - equityCapitalValue;
  const beleLoanPercentageKey = Math.round((loanTotal / purchasePriceValue) * 100 / 10) * 10;

  const zinssatzKey = `${yearsValue}-${beleLoanPercentageKey}`;
  const zinssatzObj = {
    effectiveInterest: 0,
    fixedInterest: 0,
  };

  if (zinssatz[zinssatzKey]) {
    zinssatzObj.effectiveInterest = (zinssatz[zinssatzKey]['effektiverJahreszins']);
    zinssatzObj.fixedInterest = (zinssatz[zinssatzKey]['gebundenerSollzins']);
  }

  const finalMonthlyInterestInPercent = interestType === 'percentage' ? repaymentRatePercentValue : (100 / loanTotal) * ((monthlyRateFixedValue * 12) - (loanTotal * (zinssatzObj.fixedInterest / 100)));
  const headerMonthlyRate = loanTotal * (zinssatzObj.fixedInterest + finalMonthlyInterestInPercent) / 1200;
  const yearsArray = [5, 10, 15, 20, 25, 30];

  const getZinssatzFixedInterestByYear = () => {
    const output = {};
    yearsArray.forEach(year => {
      output[year] = !zinssatz[zinssatzKey] ? '' : zinssatz[`${year}-${beleLoanPercentageKey}`]['gebundenerSollzins'];
    });
    return output;
  };

  const getZinssatzEffectiveInterestByYear = () => {
    const output = {};
    yearsArray.forEach(year => {
      output[year] = !zinssatz[zinssatzKey] ? '' : zinssatz[`${year}-${beleLoanPercentageKey}`]['effektiverJahreszins'];
    });
    return output;
  };

  const getMonthlyRateByYear = () => {
    const output = {};
    yearsArray.forEach(year => {
      const tempFixedInterest = !zinssatz[zinssatzKey] ? 0 : zinssatz[`${year}-${beleLoanPercentageKey}`]['gebundenerSollzins'];
      const tempFinalMonthlyInterestInPercent = interestType === 'percentage' ? repaymentRatePercentValue : (100 / loanTotal) * ((monthlyRateFixedValue * 12) - (loanTotal * (tempFixedInterest / 100)));
      output[year] = !zinssatz[zinssatzKey] ? '' : loanTotal * (tempFixedInterest + tempFinalMonthlyInterestInPercent) / 1200;
    });
    return output;
  };

  const getLaufzeitByYear = () => {
    const monthlyRatesObj = getMonthlyRateByYear();
    const output = {};
    yearsArray.forEach(year => {
      const fixedInterest = !zinssatz[zinssatzKey] ? '' : zinssatz[`${year}-${beleLoanPercentageKey}`]['gebundenerSollzins'];
      const tempMonthlyRate = monthlyRatesObj[year];

      output[year] = !zinssatz[zinssatzKey]
        ? ''
        : fixedInterest ? (Math.round(loanTotal / ((tempMonthlyRate * 12) - (loanTotal * (fixedInterest + finalMonthlyInterestInPercent) / (100 * 10))))) : 0;
    });
    return output;
  };

  const getRestschuld = () => {
    const monthlyRatesObj = getMonthlyRateByYear();
    const output = {};
    yearsArray.forEach(year => {
      const fixedInterest = !zinssatz[zinssatzKey] ? '' : zinssatz[`${year}-${beleLoanPercentageKey}`]['gebundenerSollzins'];
      const tempMonthlyRate = monthlyRatesObj[year];
      const tempRestSchuld = Math.round(Math.pow((1 + (fixedInterest / 100)), year) * loanTotal - (((Math.pow((1 + (fixedInterest / 100)), year) - 1) / (fixedInterest / 100)) * (tempMonthlyRate * 12)));
      output[year] = !zinssatz[zinssatzKey] ? '' : (tempRestSchuld > 1 ? tempRestSchuld : 0);
    });
    return output;
  };

  const getFixedTilgungssatz = () => {
    const output = {};
    yearsArray.forEach(year => {
      const tempFixedInterest = !zinssatz[zinssatzKey] ? 0 : zinssatz[`${year}-${beleLoanPercentageKey}`]['gebundenerSollzins'];
      output[year] = interestType === 'percentage' ? repaymentRatePercentValue : (100 / loanTotal) * ((monthlyRateFixedValue * 12) - (loanTotal * (tempFixedInterest / 100)));
    });
    return output;
  };

  const rows = [
    { name: tableRows.baseInterest, formatter: localeNumberFormatter, postFix: '%', ...getZinssatzFixedInterestByYear() },
    { name: tableRows.effectiveInterest, formatter: localeNumberFormatter, postFix: '%', ...getZinssatzEffectiveInterestByYear() },
    { name: tableRows.monthlyRate, formatter: localeMoneyFormatter, postFix: '', ...getMonthlyRateByYear() },
    { name: tableRows.percentage, formatter: localeNumberFormatter, postFix: '%', ...getFixedTilgungssatz() },
    { name: tableRows.duration, formatter: num => num, postFix: tableRows.years, ...getLaufzeitByYear() },
    { name: tableRows.breaks, formatter: localeMoneyFormatter, postFix: '', ...getRestschuld() },
  ];

  const columns = Object.keys(rows[0]).filter(keys => Number(keys));

  const showInterestError = Object.values(getZinssatzFixedInterestByYear()).filter(val => !val).length;

  return (
    <Container className={classes.container}>
      <CalculatorInput
        handleCalculationParamsChange={handleCalculationParamsChange}
        calculatorParams={newCalcParams}
        calculateRates={() => {}}
        textResource={textResource}
        resources={resources}
      />
      <Box className={classes.resultsBoxContainer}>
        <Paper className={classes.calculationResultsContainer}>
          <Grid container justify='center' spacing={0}>
            <Grid item className={classes.calculationResultPaperContainer}>
              <Paper>
                <Grid container justify='center' spacing={0} className={classes.calculationHeaderPaperGrid}>
                  <Grid item xs={4}>
                    <Typography variant='h4'>{localeMoneyFormatter(headerMonthlyRate)}</Typography>
                    <Typography variant='caption'>{header.monthlyRate}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='h4'>{localeNumberFormatter(zinssatzObj.fixedInterest)} %</Typography>
                    <Typography variant='caption'>{header.fixedInterest}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='h4'>{localeNumberFormatter(zinssatzObj.effectiveInterest)} %</Typography>
                    <Typography variant='caption'>{header.effectiveInterest}</Typography>
                  </Grid>
                </Grid>
                {
                  !rows[0][yearsValue] && !isFormHasErrors && showInterestError
                    ? <Grid className={`${classes.detailedFeesOutlineContainer} ${classes.errorMsg}`}>{errorLables.zeroSollzins}</Grid>
                    : null
                }

                <Grid container item className={classes.detailedFeesOutlineContainer} spacing={0} justify='space-between'>
                  <Grid item xs={xs}>
                    <Typography align='left' variant='h5'> {sections.loanAmount} {localeMoneyFormatter(loanTotal)}</Typography>
                  </Grid>
                </Grid>
                <Grid container justify='center' className={classes.detailedFeesOutlineContainer} spacing={2}>
                  <Grid item className={classes.textInline} xs={xs} justify='space-between'>
                    <Typography align='left' variant='body1'>
                      {tableText.purchasePrice}
                    </Typography>
                    <Typography align='left' variant='subtitle1'> {localeMoneyFormatter(purchasePriceValue)}</Typography>
                  </Grid>
                  <Grid item xs={xs}><Divider/></Grid>

                  <Grid item xs={xs} container spacing={0} justify='center'>
                    <Grid item xs={xs} className={classes.textInline} justify='space-between'>
                      <Typography className={classes.textInline} align='left' variant='body1'>
                        <span className={classes.mainTitleIcon} align='left' variant='body1'>
                          <Add color='primary'/>
                        </span>
                        {tableText.additionCost} ({(localeNumberFormatter(activeBundesland['maklerprovisionGesamt'] + activeBundesland['prozentsatz'] + 2))}%)
                      </Typography>
                      <Typography className={classes.textInline} align='left' variant='body1'>{localeMoneyFormatter(totalAdditionalCost)} </Typography>
                    </Grid>

                    <Grid className={classes.resultsListSubEntry} item xs={xs}>
                      <ArrowRight/>
                      <Typography className={classes.textInline} align='left' variant='caption'>
                        {tableText.agentFee} {localeMoneyFormatter(purchasePriceValue * activeBundesland['maklerprovisionGesamt'] / 100)} ({localeNumberFormatter(activeBundesland['maklerprovisionGesamt'])}%)
                      </Typography>
                    </Grid>
                    <Grid className={classes.resultsListSubEntry} item xs={xs}>
                      <ArrowRight/>
                      <Typography className={classes.textInline} align='left' variant='caption'>
                        {tableText.ground} {localeMoneyFormatter(purchasePriceValue * activeBundesland['prozentsatz'] / 100)} ({localeNumberFormatter(activeBundesland['prozentsatz'])}%)
                      </Typography>
                    </Grid>
                    <Grid className={classes.resultsListSubEntry} item xs={xs}>
                      <ArrowRight/>
                      <Typography className={classes.textInline} align='left' variant='caption'>
                        {tableText.notaryFree} {localeMoneyFormatter(purchasePriceValue * 2 / 100)} ({localeNumberFormatter(2)}%)
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={xs}><Divider/></Grid>

                  <Grid item xs={xs} className={classes.textInline} justify='space-between'>
                    <Typography className={classes.textInline} align='left' variant='body1'>
                      <span className={classes.mainTitleIcon} align='left' variant='body1'>
                        <Remove color='primary'/>
                      </span>
                      {tableText.capital}
                    </Typography>
                    <Typography align='left' variant='body1'>{localeMoneyFormatter(equityCapitalValue)}</Typography>
                  </Grid>
                  <Grid item xs={xs}><Divider className={classes.divider}/></Grid>
                  <Grid item xs={xs} className={classes.textInline} justify='space-between'>
                    <Typography className={classes.textInline} align='left' variant='body1'>
                      <span className={classes.mainTitleIcon} align='left' variant='body1' color="primary"><DragHandle color='primary'/>
                      </span>
                      {tableText.loanAmount}
                    </Typography>
                    <Typography align='left' variant='body1' color="primary">{localeMoneyFormatter(loanTotal)}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid className={`${classes.calculationResultPaperContainer} ${classes.durationAccordionGridContainer}`} item>
              <Accordion className={classes.durationAccordion} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>{sections.duration}</Typography>
                </AccordionSummary>
                <Box className={classes.tableBox}>
                  <TableContainer slot="container-start" className={[classes.slider, classes.fixedColumn].join(' ')} component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.hideText} color='primary' align="left">{` ph`}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map(({ name }) => (
                          <TableRow key={`table-row-key-${name}`}>
                            <TableCell align="left">{name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <FvmTableSwiper rows={rows} columns={columns} slidesPerView={slidesPerView} yearsValue={yearsValue} tableHeader={tableHeader} textResource={tableRows}/>
                </Box>
                <div className={classes.swiperControls}>
                  <span className="fvm-prevElm-button">
                    <IconButton> <ArrowBack/> </IconButton>
                    <span>{buttons.forward}</span>
                  </span>
                  <span className="fvm-nextElm-button">
                    <span>{buttons.back}</span>
                    <IconButton><ArrowForward/></IconButton>
                  </span>
                </div>
                <div className={classes.tableFootnote}>
                  <Typography variant='caption'>{footnotesTxt.tableFootnote}</Typography>
                </div>
              </Accordion>
            </Grid>

            <Grid item container className={classes.calculationResultPaperContainer} justify='center' direction="row">
              <Grid item>
                <Button className="fvm-button-appointment" color='primary' disabled={!rows[0][yearsValue] || isFormHasErrors} onClick={handleOnSubmitClicked} variant='contained'>{buttons.makeAppointmentNow}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {isLoading ? <LoadingSpinner/> : null}
      </Box>
    </Container>
  );
};

const CalculatorInput = ({ handleCalculationParamsChange, calculatorParams, calculateRates, textResource, resources }) => {
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const buttonGroupStyles = useButtonGroupStyles();
  const { plzBundesland } = resources;
  const { purchasePrice, equityCapital, yearsValue, interestType, repaymentRatePercent, monthlyRateFixed } = calculatorParams;
  const { currentLocation, currentResidencePostcode, financingProject, propertyType, occupationOfMainEarner, propertyUse } = calculatorParams;
  const [yearsValueLocal, setYearsValue] = React.useState(yearsValue);
  const [localCurrentResidencePostcode, setLocalCurrentResidencePostcode] = React.useState(currentResidencePostcode);
  const [plzLength, setPlzLength] = React.useState(0);
  const [fieldErrors, setFieldErrors] = React.useState({
    purchasePrice: false,
    equityCapital: false,
    currentResidencePostcode: false,
    repaymentRatePercent: false,
    monthlyRateFixed: false,
  });

  const calculateRatesLocal = () => {
    const errorResult = determineErrors();
    setFieldErrors({ ...errorResult });

    if (!errorResult.isFormHasErrors) {
      calculateRates();
    }
  };

  const determineErrors = (fields = {}) => {
    const evalField = input => input === null || input === undefined || input === '';
    const errorResult = {
      purchasePrice: evalField(fields.purchasePrice || purchasePrice),
      equityCapital: evalField(fields.equityCapital || equityCapital),
      currentResidencePostcode: evalField(fields.currentResidencePostcode || currentResidencePostcode) || (`${fields.currentResidencePostcode || currentResidencePostcode}`.length !== 5),
      repaymentRatePercent: evalField(fields.repaymentRatePercent || repaymentRatePercent),
      monthlyRateFixed: evalField(fields.monthlyRateFixed || monthlyRateFixed),
    };

    if (errorResult.purchasePrice || errorResult.equityCapital || errorResult.currentResidencePostcode || ((fields.interestType || interestType) === 'percentage' ? errorResult.repaymentRatePercent : errorResult.monthlyRateFixed)) {
      setTimeout(handleCalculationParamsChange({ isFormHasErrors: true }), 1000);
      return { ...errorResult, isFormHasErrors: true };
    } else {
      setTimeout(handleCalculationParamsChange({ isFormHasErrors: false }), 1000);
      return { ...errorResult, isFormHasErrors: false };
    }
  };

  if (currentResidencePostcode !== localCurrentResidencePostcode) {
    setLocalCurrentResidencePostcode(currentResidencePostcode);
    determineErrors();
  }


  const handleRadioChange = (event, yearsValue) => {
    if (yearsValue) {
      setYearsValue(yearsValue);
      handleCalculationParamsChange({ yearsValue });
    }
  };


  const onCurrentResidencePostcode = (event) => {
    const { target } = event;
    const { value, outerText, innerHTML } = target;
    handleCalculationParamsChange({ currentResidencePostcode: (value || innerHTML || outerText) ? (value || innerHTML || outerText).replace(/\D+/g, '') : null });
    setPlzLength((target.value || '').length);
    determineErrors();
  };

  const { buttons, textField, select, sections } = textResource.labels;
  const { toolTips } = textResource;
  return (
    <Box className={classes.calculatorBoxContainer}>
      <Paper className={classes.calculatorContainer}>
        <Grid className={classes.calculatorContainerInnerGrid} container justify='center' direction='column' spacing={0}>
          <Grid item xs={xs}>
            <TextField
              className={classes.formControlTextField}
              error={fieldErrors.purchasePrice}
              fullWidth
              required
              label={textField.purchasePrice}
              variant={textFieldVariant}
              value={purchasePrice}
              onChange={({ target }) => {
                handleCalculationParamsChange({ purchasePrice: target.value });
                determineErrors({ purchasePrice: target.value });
              }}
              InputProps={{
                inputComponent: NumberFormatMoneyDE,
                ...getTextFieldPostfix(),
              }}
            />
            <FvmHelpIcon toolTipText={toolTips.purchasePrice}/>
          </Grid>
          <Grid item xs={xs}>
            <TextField
              className={classes.formControlTextField}
              error={fieldErrors.equityCapital}
              fullWidth
              required
              label={textField.equityCapital}
              variant={textFieldVariant}
              value={equityCapital}
              onChange={({ target }) => {
                handleCalculationParamsChange({ equityCapital: target.value });
                determineErrors({ equityCapital: target.value });
              }}
              InputProps={{
                inputComponent: NumberFormatMoneyDE,
                ...getTextFieldPostfix(),
              }}
            />
            <FvmHelpIcon toolTipText={toolTips.equityCapital}/>
            <div>

            </div>
          </Grid>

          <Grid item xs={xs}>
            <FormControl variant={textFieldVariant} label={textField.interestType} className={classes.formControlTextField}>
              <Autocomplete
                id="plz-form"
                freeSolo
                options={
                  plzLength < 2
                    ? []
                    : Object.values(plzBundesland)
                }
                label={textField.interestType}
                getOptionLabel={({ plz }) => plz}
                renderOption={({ plz, ort, landkreis }) => `${plz} ${ort} ${landkreis ? `(${landkreis})` : ''}`}
                onInputChange={onCurrentResidencePostcode}
                // inputValue={currentResidencePostcode}
                renderInput={(params) => {
                  params.InputProps = {
                    inputComponent: NumberFormatInputPostCode,
                    ...params.InputProps,
                  };
                  return (
                    <TextField
                      {...params}
                      error={fieldErrors.currentResidencePostcode}
                      required
                      label={textField.currentResidencePostcode} variant={textFieldVariant}
                    />
                  );
                }}
                filterOptions={filterOptions}
              />
            </FormControl>
            <FvmHelpIcon toolTipText={toolTips.currentResidencePostcode}/>
          </Grid>
          <Grid item xs={xs}>
            <TextField
              className={classes.formControlTextField}
              fullWidth
              label={textField.currentLocation}
              value={currentLocation}
              InputLabelProps={{
                shrink: !!currentLocation,
              }}
              variant={textFieldVariant}
              onChange={({ target }) => {
                handleCalculationParamsChange({ currentLocation: target.value });
              }}
            />
            <FvmHelpIcon toolTipText={toolTips.currentLocation}/>
          </Grid>

          <Grid item xs={xs}>
            <InputLabel className={classes.formButtonGroupLabel}>{buttons.yearSelectionButtonText}</InputLabel>
          </Grid>
          <Grid item xs={xs}>
            <FormControl variant={textFieldVariant} className={classes.formControlButtonGroup}>
              <ToggleButtonGroup
                value={yearsValueLocal}
                size='large'
                exclusive
                onChange={handleRadioChange} aria-label="Year Selection">
                {
                  [5, 10, 15, 20, 25, 30].map(val =>
                    <ToggleButton
                      key={`button-key${val}`}
                      className={val === yearsValueLocal ? buttonGroupStyles.root : ''}
                      value={val} color='primary'
                      aria-label={`${val}-years`}>{val}
                    </ToggleButton>,
                  )
                }
              </ToggleButtonGroup>
            </FormControl>
            <FvmHelpIcon toolTipText={toolTips.nominalInterestInYearInterestInYear}/>
          </Grid>

          <Grid item xs={xs}>
            <FormControl variant={textFieldVariant} className={classes.formControlSelect}>
              <InputLabel id="demo-simple-select-outlined-label">{select.interestType}</InputLabel>
              <Select
                label={select.interestType}
                variant={textFieldVariant}
                value={interestType}
                onChange={({ target }) => {
                  handleCalculationParamsChange({ interestType: target.value });
                  determineErrors({ interestType: target.value });
                }}
              >
                <MenuItem className={selectClasses.root} value='percentage'>{select.repaymentRateInPercent}</MenuItem>
                <MenuItem value='fixed'>{select.monthlyRateAsFixed}</MenuItem>
              </Select>

            </FormControl>
            <FvmHelpIcon toolTipText={toolTips.interestType}/>
          </Grid>
          {
            interestType === 'percentage'
              ? <Grid item xs={xs}>
                <TextField
                  className={classes.formControlTextField}
                  fullWidth
                  error={fieldErrors.repaymentRatePercent}
                  required
                  label={textField.repaymentRatePercent}
                  variant={textFieldVariant}
                  InputProps={{
                    // inputComponent: (props,ff) => NumberFormatMoneyDE({ fixedDecimalScale: true, ...props }),
                    inputComponent: NumberFormatPercentageInputDE,
                    ...getTextFieldPostfix('%'),
                  }}
                  value={repaymentRatePercent}
                  onChange={({ target }) => {
                    handleCalculationParamsChange({ repaymentRatePercent: target.value });
                    determineErrors({ repaymentRatePercent: target.value });
                  }}
                />
                <FvmHelpIcon toolTipText={toolTips.repaymentRatePercent}/>
              </Grid>
              : <Grid item xs={xs}>
                <TextField
                  className={classes.formControlTextField}
                  fullWidth
                  error={fieldErrors.monthlyRateFixed}
                  required
                  label={textField.monthlyRateFixed}
                  variant={textFieldVariant}
                  value={monthlyRateFixed}
                  InputProps={{
                    inputComponent: NumberFormatMoneyDE,
                    ...getTextFieldPostfix(),
                  }}
                  onChange={({ target }) => {
                    handleCalculationParamsChange({ monthlyRateFixed: target.value });
                    determineErrors({ monthlyRateFixed: target.value });
                  }}
                >
                </TextField>
                <FvmHelpIcon toolTipText={toolTips.monthlyRateFixed}/>
              </Grid>
          }

          <Grid item xs={xs}>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading} color='primary'>{sections.moreOptions}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid className={classes.accordionInnerGrid} container justify='center' direction='column' spacing={0}>
                  <Grid item xs={xs}>
                    <FormControl variant={textFieldVariant} className={[classes.formControlSelect, classes.formControlAccordionSelect].join(' ')}>
                      <InputLabel id="demo-simple-select-outlined-label">{select.financingProject}</InputLabel>
                      <Select
                        label={select.financingProject}
                        variant={textFieldVariant}
                        onChange={({ target }) => handleCalculationParamsChange({ financingProject: target.value })}
                        // onChange={({ target }) => console.log({ financingProject: target.value })}
                        value={financingProject}
                      >
                        {Finanzierungsvorhaben.map((value) => <MenuItem key={`menu-item-key${value}`} value={value}>{value} </MenuItem>)}
                      </Select>
                    </FormControl>
                    <FvmHelpIcon toolTipText={toolTips.financingProject}/>
                  </Grid>
                  <Grid item xs={xs}>
                    <FormControl variant={textFieldVariant} className={[classes.formControlSelect, classes.formControlAccordionSelect].join(' ')}>
                      <InputLabel id="demo-simple-select-outlined-label">{select.propertyType}</InputLabel>
                      <Select
                        label={select.propertyType}
                        variant={textFieldVariant}
                        onChange={({ target }) => handleCalculationParamsChange({ propertyType: target.value })}
                        value={propertyType}
                      >
                        {Immobilientyp.map((value) => <MenuItem key={`menu-item-key${value}`} value={value}>{value} </MenuItem>)}
                      </Select>
                    </FormControl>
                    <FvmHelpIcon toolTipText={toolTips.propertyType}/>
                  </Grid>
                  <Grid item xs={xs}>
                    <FormControl variant={textFieldVariant} className={[classes.formControlSelect, classes.formControlAccordionSelect].join(' ')}>
                      <InputLabel id="demo-simple-select-outlined-label">{select.occupationOfMainEarner}</InputLabel>
                      <Select
                        label={select.occupationOfMainEarner}
                        variant={textFieldVariant}
                        onChange={({ target }) => handleCalculationParamsChange({ occupationOfMainEarner: target.value })}
                        value={occupationOfMainEarner}
                      >
                        {BerufDesHauptverdieners.map((value) => <MenuItem key={`menu-item-key${value}`} value={value}>{value} </MenuItem>)}
                      </Select>
                    </FormControl>
                    <FvmHelpIcon toolTipText={toolTips.occupationOfMainEarner}/>
                  </Grid>
                  <Grid item xs={xs}>

                    <FormControl variant={textFieldVariant} className={[classes.formControlSelect, classes.formControlAccordionSelect].join(' ')}>
                      <InputLabel id="demo-simple-select-outlined-label">{select.propertyUse}</InputLabel>
                      <Select
                        label={select.propertyUse}
                        variant={textFieldVariant}
                        onChange={({ target }) => handleCalculationParamsChange({ propertyUse: target.value })}
                        value={propertyUse}
                      >
                        {NutzungDerImmobilie.map((value) => <MenuItem key={`menu-item-key${value}`} value={value}>{value} </MenuItem>)}
                      </Select>
                    </FormControl>
                    <FvmHelpIcon toolTipText={toolTips.propertyUse}/>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            xs={xs}
            spacing={0}
          >
            <Grid item>
              <Button color='primary' variant='contained' onClick={calculateRatesLocal}>{buttons.calculate}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>

  );
};

//Helper components
function ArrowRight() {
  const classes = useStyles();

  return (
    <Typography className={classes.subLstItemIcon} align='left' variant='caption'>
      <ArrowForwardIos fontSize='small' color='primary'/>
    </Typography>
  );
}


const getTextFieldPostfix = (text = '€') => ({
  endAdornment: <InputAdornment position="start">{text}</InputAdornment>,
});




